import { useI18n } from 'vue-i18n';

import { showConfirm } from '@/composables/useDialog';
import { PRODUCT_HOME_PAGE_URL } from '@/constants/url.const';
import { redirectTo } from '@/utils/common.util';

export const useProduct = () => {
  const { t } = useI18n();
  const showCompleteSaveDialog = async () => {
    const confirm = await showConfirm({
      content: t('studio.common.popup_case_d_complete_save'),
      confirmLabel: t('studio.redirect_prod_home_btn'),
      cancelLabel: t('studio.gnb.group_myinfo_terms1_popup_btn_close'),
      cancelVariant: 'outline'
    });

    if (confirm) {
      redirectTo(PRODUCT_HOME_PAGE_URL);
    }

    return confirm;
  };

  return {
    showCompleteSaveDialog
  };
};
